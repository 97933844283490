<script setup>
defineProps({
    message: String,
});

const handleError = () => clearError({ redirect: '/' });
</script>

<template>
    <ErrorDisplay
        :message="message"
        :error-code="404"
        @handle-error="handleError"
    />
</template>
