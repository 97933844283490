<script setup>
import PageLink from './header/PageLink.vue';

const { data: profile } = useAuth();

const { t } = useI18n();

defineProps({
    isActiveMember: Boolean,
});

const open = ref(false);

function close() {
    if (open.value) {
        open.value = false;
    }

    document.body.classList.remove('nav-open');
}

const router = useRouter();

router.afterEach(() => {
    close();
});

const navigation = [
    {
        label: t('pages.dashboard'),
        link: '/members/dashboard',
    },
    {
        label: t('pages.discover'),
        link: '/members/discover',
        highlight: () => profile.value?.has_new_topics_to_discover || false,
        show: () => profile.value?.can_discover_topics || false,
    },
    {
        label: t('global.calendar'),
        link: '/members/calendar',
    },
    {
        label: t('global.documents'),
        link: '/members/documents',
    },
];
</script>

<template>
    <header
        id="user-header"
        ref="header"
        class="w-full absolute top-0 left-0 right-0 z-20"
    >
        <nav class="container mx-auto py-6 md:py-8 xl:py-12">
            <div class="flex items-center justify-between">
                <MemberHeaderAppLogo v-bind="profile?.team?.logo" />

                <div v-if="isActiveMember" class="flex items-center gap-8">
                    <ul
                        class="hidden md:flex gap-4 md:gap-5 lg:gap-8 text-sm lg:text-base"
                    >
                        <PageLink
                            v-if="profile?.frontend_management_access"
                            :label="$t('admin.dashboard.title')"
                            link="/admin"
                            external
                        />
                        <PageLink
                            v-for="(nav, index) in navigation"
                            :key="`link-${index}`"
                            :show="nav.show"
                            :label="nav.label"
                            :link="nav.link"
                            :highlight="nav.highlight ? nav.highlight() : false"
                        />
                    </ul>
                    <div class="relative">
                        <slot name="profile" />
                    </div>
                </div>
            </div>
        </nav>
    </header>
</template>
