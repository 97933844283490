import type * as Sentry from '@sentry/node';

const IGNORED_STATUSES = [401, 404, 422, 409, 500];

const handleException = (exception: Sentry.Exception) => {
    // This exception is thrown/triggered by vimeo-player.js
    if (exception.type === 'TypeError') {
        // get last element of the stack trace
        const lastFrame = exception.stacktrace?.frames?.slice(-1)[0];

        // check if the last frame is from vimeo-player.js
        if (lastFrame?.filename?.includes('vimeo-player.js')) {
            return false;
        }
    }

    // The exception is not ignored
    return true;
};

const beforeSendHook = (event: Sentry.Event, hint?: Sentry.EventHint) => {
    const error = hint?.originalException;

    if (!error) {
        return event;
    }

    // Ignore certain fetch errors
    if (error.name === "FetchError") {
        const response = error.response as Response;

        if (IGNORED_STATUSES.includes(response?.status)) {
            return null;
        }

    }

    // Ignore certain statuses
    if (error instanceof Response && IGNORED_STATUSES.includes(error.status)) {
        return null;
    }

    const exception = event.exception?.values?.[0];

    // Ignore certain exceptions
    if (exception) {
        return handleException(exception) ? event : null;
    }

    return event;

};

export default beforeSendHook;
