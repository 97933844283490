import { useScript, defineNuxtPlugin } from '#imports'

export default defineNuxtPlugin({
  name: "scripts:init",
  env: { islands: false },
  parallel: true,
  setup() {
    const ccm = useScript({"key":"ccm","src":"https://5f3c395.ccm19.de/app/public/ccm19.js?apiKey=5d8c70493d06c746b56b4dfc2d0c01dafe9a1e0721f44a6d","referrerPolicy":"origin"}, { use: () => ({ ccm: window.ccm }) })
    return { provide: { $scripts: { ccm } } }
  }
})