<script setup>
const props = defineProps({
    label: {
        type: String,
        required: true,
    },
    link: {
        type: String,
        default: '/',
    },
    highlight: {
        type: Boolean,
        default: false,
    },
    show: {
        type: [Boolean, Function],
        default: true,
    },
    external: {
        type: Boolean,
        default: false,
    },
});

const show = computed(() => {
    if (typeof props.show === 'function') {
        return props.show();
    }

    return props.show;
});
</script>

<template>
    <li v-if="show">
        <NuxtLink
            exact-active-class="active text-brand [&>svg]:text-brand"
            :class="highlight || external ? 'flex items-center' : ''"
            :to="link"
        >
            <IconExternalLink
                v-if="external"
                class="w-4 h-4 flex-shrink-0 flex mr-2"
            />
            <span
                v-if="highlight"
                class="w-2.5 h-2.5 rounded-full bg-brand flex-shrink-0 flex mr-2"
            />
            {{ label }}
        </NuxtLink>
    </li>
</template>
