import { default as _91token_93OaUlcvCwDjMeta } from "/var/www/html/pages/activate-user/[token].vue?macro=true";
import { default as _91token_93IX2VuDGxnDMeta } from "/var/www/html/pages/activation-code/[token].vue?macro=true";
import { default as indexuAHfSzeI0zMeta } from "/var/www/html/pages/activation-code/index.vue?macro=true";
import { default as account8YAxl2ddUQMeta } from "/var/www/html/pages/admin/[team]/[product]/account.vue?macro=true";
import { default as indexPkrvODuUFnMeta } from "/var/www/html/pages/admin/[team]/[product]/certificate-archive/index.vue?macro=true";
import { default as indexyx8Fb4m9FAMeta } from "/var/www/html/pages/admin/[team]/[product]/certificates/index.vue?macro=true";
import { default as dashboard8aqPEOTVndMeta } from "/var/www/html/pages/admin/[team]/[product]/dashboard.vue?macro=true";
import { default as _91id_93UmVpMO4cgsMeta } from "/var/www/html/pages/admin/[team]/[product]/employees/[id].vue?macro=true";
import { default as _91id_93l2yxSuOu6xMeta } from "/var/www/html/pages/admin/[team]/[product]/employees/imports/[id].vue?macro=true";
import { default as indexWOjJh5yKTVMeta } from "/var/www/html/pages/admin/[team]/[product]/employees/index.vue?macro=true";
import { default as index2bhJrgdxmkMeta } from "/var/www/html/pages/admin/[team]/[product]/employees/sap/index.vue?macro=true";
import { default as _91id_93Et8HxGMZEJMeta } from "/var/www/html/pages/admin/[team]/[product]/groups/[id].vue?macro=true";
import { default as index4J9PNUb31vMeta } from "/var/www/html/pages/admin/[team]/[product]/groups/index.vue?macro=true";
import { default as indexCaHbS4EyeqMeta } from "/var/www/html/pages/admin/[team]/[product]/index.vue?macro=true";
import { default as _91id_93NLk1FiTzEOMeta } from "/var/www/html/pages/admin/[team]/[product]/learning-path/[id].vue?macro=true";
import { default as _91id_93nfmTf1vCVqMeta } from "/var/www/html/pages/admin/[team]/[product]/learning-path/[learning-path-id]/[id].vue?macro=true";
import { default as indexgCFEgSxLeFMeta } from "/var/www/html/pages/admin/[team]/[product]/learning-path/index.vue?macro=true";
import { default as _91id_93UkFrb3pWfpMeta } from "/var/www/html/pages/admin/[team]/[product]/topic-catalog/[id].vue?macro=true";
import { default as indexvJ2mR9V2JgMeta } from "/var/www/html/pages/admin/[team]/[product]/topic-catalog/index.vue?macro=true";
import { default as _91id_93Rqe64KmuEXMeta } from "/var/www/html/pages/admin/[team]/[product]/topics/[id].vue?macro=true";
import { default as _91foreignId_93n7XVYLlVZNMeta } from "/var/www/html/pages/admin/[team]/[product]/topics/[topic]/builder/[foreignId].vue?macro=true";
import { default as indexbFUhNLfNLzMeta } from "/var/www/html/pages/admin/[team]/[product]/topics/index.vue?macro=true";
import { default as _91id_93b1ebIYLwWmMeta } from "/var/www/html/pages/admin/[team]/[product]/trainings/[id].vue?macro=true";
import { default as indexCFi4r2jlI9Meta } from "/var/www/html/pages/admin/[team]/[product]/trainings/index.vue?macro=true";
import { default as indextA7PX6NQnzMeta } from "/var/www/html/pages/admin/[team]/index.vue?macro=true";
import { default as migrationY5bH4qDGD1Meta } from "/var/www/html/pages/admin/[team]/migration.vue?macro=true";
import { default as indexWdLX2IWjNqMeta } from "/var/www/html/pages/admin/index.vue?macro=true";
import { default as loginW3jEEN6dETMeta } from "/var/www/html/pages/admin/login.vue?macro=true";
import { default as signout2YKTc3rk01Meta } from "/var/www/html/pages/auth/signout.vue?macro=true";
import { default as indexUbNeHw0dYYMeta } from "/var/www/html/pages/index.vue?macro=true";
import { default as archivm7zOTuYvhfMeta } from "/var/www/html/pages/members/archiv.vue?macro=true";
import { default as calendarMEZLCxUvsNMeta } from "/var/www/html/pages/members/calendar.vue?macro=true";
import { default as dashboardShutYOtVuIMeta } from "/var/www/html/pages/members/dashboard.vue?macro=true";
import { default as _91topic_93kiBmwKAmsvMeta } from "/var/www/html/pages/members/discover/[topic].vue?macro=true";
import { default as indexPND2QzO4aVMeta } from "/var/www/html/pages/members/discover/index.vue?macro=true";
import { default as documentszmMOB6cuhZMeta } from "/var/www/html/pages/members/documents.vue?macro=true";
import { default as indexKEPJOWrZ6nMeta } from "/var/www/html/pages/members/learning-path/[path]/index.vue?macro=true";
import { default as _91module_93ueonC1Vt73Meta } from "/var/www/html/pages/members/learning/[course]/module/[module].vue?macro=true";
import { default as indexowT7llBl9jMeta } from "/var/www/html/pages/members/learning/[course]/module/index.vue?macro=true";
import { default as login2lNwWtxw97Meta } from "/var/www/html/pages/members/login.vue?macro=true";
import { default as _91module_93EXmsixdalBMeta } from "/var/www/html/pages/members/participation/[slug]/elearning/[course]/[module].vue?macro=true";
import { default as index8iNNoeFQgrMeta } from "/var/www/html/pages/members/participation/[slug]/elearning/[course]/index.vue?macro=true";
import { default as indexX03rBJCgG6Meta } from "/var/www/html/pages/members/participation/[slug]/index.vue?macro=true";
import { default as profileYp1qWyzuSfMeta } from "/var/www/html/pages/members/profile.vue?macro=true";
import { default as _91token_93C0KzpAlqt0Meta } from "/var/www/html/pages/reset-password/[token].vue?macro=true";
import { default as by_45tokennwHLpSduQHMeta } from "/var/www/html/pages/reset-password/by-token.vue?macro=true";
import { default as changefnfBm7l90vMeta } from "/var/www/html/pages/reset-password/change.vue?macro=true";
import { default as indexzcQPnc5ld1Meta } from "/var/www/html/pages/reset-password/index.vue?macro=true";
export default [
  {
    name: "activate-user-token",
    path: "/activate-user/:token()",
    meta: _91token_93OaUlcvCwDjMeta || {},
    component: () => import("/var/www/html/pages/activate-user/[token].vue").then(m => m.default || m)
  },
  {
    name: "activation-code-token",
    path: "/activation-code/:token()",
    meta: _91token_93IX2VuDGxnDMeta || {},
    component: () => import("/var/www/html/pages/activation-code/[token].vue").then(m => m.default || m)
  },
  {
    name: "activation-code",
    path: "/activation-code",
    meta: indexuAHfSzeI0zMeta || {},
    component: () => import("/var/www/html/pages/activation-code/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-team-product-account",
    path: "/admin/:team()/:product()/account",
    meta: account8YAxl2ddUQMeta || {},
    component: () => import("/var/www/html/pages/admin/[team]/[product]/account.vue").then(m => m.default || m)
  },
  {
    name: "admin-team-product-certificate-archive",
    path: "/admin/:team()/:product()/certificate-archive",
    meta: indexPkrvODuUFnMeta || {},
    component: () => import("/var/www/html/pages/admin/[team]/[product]/certificate-archive/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-team-product-certificates",
    path: "/admin/:team()/:product()/certificates",
    meta: indexyx8Fb4m9FAMeta || {},
    component: () => import("/var/www/html/pages/admin/[team]/[product]/certificates/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-team-product-dashboard",
    path: "/admin/:team()/:product()/dashboard",
    meta: dashboard8aqPEOTVndMeta || {},
    component: () => import("/var/www/html/pages/admin/[team]/[product]/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "admin-team-product-employees-id",
    path: "/admin/:team()/:product()/employees/:id()",
    meta: _91id_93UmVpMO4cgsMeta || {},
    component: () => import("/var/www/html/pages/admin/[team]/[product]/employees/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-team-product-employees-imports-id",
    path: "/admin/:team()/:product()/employees/imports/:id()",
    meta: _91id_93l2yxSuOu6xMeta || {},
    component: () => import("/var/www/html/pages/admin/[team]/[product]/employees/imports/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-team-product-employees",
    path: "/admin/:team()/:product()/employees",
    meta: indexWOjJh5yKTVMeta || {},
    component: () => import("/var/www/html/pages/admin/[team]/[product]/employees/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-team-product-employees-sap",
    path: "/admin/:team()/:product()/employees/sap",
    meta: index2bhJrgdxmkMeta || {},
    component: () => import("/var/www/html/pages/admin/[team]/[product]/employees/sap/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-team-product-groups-id",
    path: "/admin/:team()/:product()/groups/:id()",
    meta: _91id_93Et8HxGMZEJMeta || {},
    component: () => import("/var/www/html/pages/admin/[team]/[product]/groups/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-team-product-groups",
    path: "/admin/:team()/:product()/groups",
    meta: index4J9PNUb31vMeta || {},
    component: () => import("/var/www/html/pages/admin/[team]/[product]/groups/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-team-product",
    path: "/admin/:team()/:product()",
    meta: indexCaHbS4EyeqMeta || {},
    component: () => import("/var/www/html/pages/admin/[team]/[product]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-team-product-learning-path-id",
    path: "/admin/:team()/:product()/learning-path/:id()",
    meta: _91id_93NLk1FiTzEOMeta || {},
    component: () => import("/var/www/html/pages/admin/[team]/[product]/learning-path/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-team-product-learning-path-learningpathid-id",
    path: "/admin/:team()/:product()/learning-path/:learningpathid()/:id()",
    meta: _91id_93nfmTf1vCVqMeta || {},
    component: () => import("/var/www/html/pages/admin/[team]/[product]/learning-path/[learning-path-id]/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-team-product-learning-path",
    path: "/admin/:team()/:product()/learning-path",
    meta: indexgCFEgSxLeFMeta || {},
    component: () => import("/var/www/html/pages/admin/[team]/[product]/learning-path/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-team-product-topic-catalog-id",
    path: "/admin/:team()/:product()/topic-catalog/:id()",
    meta: _91id_93UkFrb3pWfpMeta || {},
    component: () => import("/var/www/html/pages/admin/[team]/[product]/topic-catalog/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-team-product-topic-catalog",
    path: "/admin/:team()/:product()/topic-catalog",
    meta: indexvJ2mR9V2JgMeta || {},
    component: () => import("/var/www/html/pages/admin/[team]/[product]/topic-catalog/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-team-product-topics-id",
    path: "/admin/:team()/:product()/topics/:id()",
    meta: _91id_93Rqe64KmuEXMeta || {},
    component: () => import("/var/www/html/pages/admin/[team]/[product]/topics/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-team-product-topics-topic-builder-foreignId",
    path: "/admin/:team()/:product()/topics/:topic()/builder/:foreignId()",
    meta: _91foreignId_93n7XVYLlVZNMeta || {},
    component: () => import("/var/www/html/pages/admin/[team]/[product]/topics/[topic]/builder/[foreignId].vue").then(m => m.default || m)
  },
  {
    name: "admin-team-product-topics",
    path: "/admin/:team()/:product()/topics",
    meta: indexbFUhNLfNLzMeta || {},
    component: () => import("/var/www/html/pages/admin/[team]/[product]/topics/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-team-product-trainings-id",
    path: "/admin/:team()/:product()/trainings/:id()",
    meta: _91id_93b1ebIYLwWmMeta || {},
    component: () => import("/var/www/html/pages/admin/[team]/[product]/trainings/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-team-product-trainings",
    path: "/admin/:team()/:product()/trainings",
    meta: indexCFi4r2jlI9Meta || {},
    component: () => import("/var/www/html/pages/admin/[team]/[product]/trainings/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-team",
    path: "/admin/:team()",
    meta: indextA7PX6NQnzMeta || {},
    component: () => import("/var/www/html/pages/admin/[team]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-team-migration",
    path: "/admin/:team()/migration",
    meta: migrationY5bH4qDGD1Meta || {},
    component: () => import("/var/www/html/pages/admin/[team]/migration.vue").then(m => m.default || m)
  },
  {
    name: "admin",
    path: "/admin",
    meta: indexWdLX2IWjNqMeta || {},
    component: () => import("/var/www/html/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-login",
    path: "/admin/login",
    meta: loginW3jEEN6dETMeta || {},
    component: () => import("/var/www/html/pages/admin/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-signout",
    path: "/auth/signout",
    meta: signout2YKTc3rk01Meta || {},
    component: () => import("/var/www/html/pages/auth/signout.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexUbNeHw0dYYMeta || {},
    component: () => import("/var/www/html/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "members-archiv",
    path: "/members/archiv",
    meta: archivm7zOTuYvhfMeta || {},
    component: () => import("/var/www/html/pages/members/archiv.vue").then(m => m.default || m)
  },
  {
    name: "members-calendar",
    path: "/members/calendar",
    meta: calendarMEZLCxUvsNMeta || {},
    component: () => import("/var/www/html/pages/members/calendar.vue").then(m => m.default || m)
  },
  {
    name: "members-dashboard",
    path: "/members/dashboard",
    meta: dashboardShutYOtVuIMeta || {},
    component: () => import("/var/www/html/pages/members/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "members-discover-topic",
    path: "/members/discover/:topic()",
    meta: _91topic_93kiBmwKAmsvMeta || {},
    component: () => import("/var/www/html/pages/members/discover/[topic].vue").then(m => m.default || m)
  },
  {
    name: "members-discover",
    path: "/members/discover",
    meta: indexPND2QzO4aVMeta || {},
    component: () => import("/var/www/html/pages/members/discover/index.vue").then(m => m.default || m)
  },
  {
    name: "members-documents",
    path: "/members/documents",
    meta: documentszmMOB6cuhZMeta || {},
    component: () => import("/var/www/html/pages/members/documents.vue").then(m => m.default || m)
  },
  {
    name: "members-learning-path-path",
    path: "/members/learning-path/:path()",
    meta: indexKEPJOWrZ6nMeta || {},
    component: () => import("/var/www/html/pages/members/learning-path/[path]/index.vue").then(m => m.default || m)
  },
  {
    name: "members-learning-course-module-module",
    path: "/members/learning/:course()/module/:module()",
    meta: _91module_93ueonC1Vt73Meta || {},
    component: () => import("/var/www/html/pages/members/learning/[course]/module/[module].vue").then(m => m.default || m)
  },
  {
    name: "members-learning-course-module",
    path: "/members/learning/:course()/module",
    meta: indexowT7llBl9jMeta || {},
    component: () => import("/var/www/html/pages/members/learning/[course]/module/index.vue").then(m => m.default || m)
  },
  {
    name: "members-login",
    path: "/members/login",
    meta: login2lNwWtxw97Meta || {},
    component: () => import("/var/www/html/pages/members/login.vue").then(m => m.default || m)
  },
  {
    name: "members-participation-slug-elearning-course-module",
    path: "/members/participation/:slug()/elearning/:course()/:module()",
    meta: _91module_93EXmsixdalBMeta || {},
    component: () => import("/var/www/html/pages/members/participation/[slug]/elearning/[course]/[module].vue").then(m => m.default || m)
  },
  {
    name: "members-participation-slug-elearning-course",
    path: "/members/participation/:slug()/elearning/:course()",
    meta: index8iNNoeFQgrMeta || {},
    component: () => import("/var/www/html/pages/members/participation/[slug]/elearning/[course]/index.vue").then(m => m.default || m)
  },
  {
    name: "members-participation-slug",
    path: "/members/participation/:slug()",
    meta: indexX03rBJCgG6Meta || {},
    component: () => import("/var/www/html/pages/members/participation/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "members-profile",
    path: "/members/profile",
    meta: profileYp1qWyzuSfMeta || {},
    component: () => import("/var/www/html/pages/members/profile.vue").then(m => m.default || m)
  },
  {
    name: "reset-password-token",
    path: "/reset-password/:token()",
    meta: _91token_93C0KzpAlqt0Meta || {},
    component: () => import("/var/www/html/pages/reset-password/[token].vue").then(m => m.default || m)
  },
  {
    name: "reset-password-by-token",
    path: "/reset-password/by-token",
    meta: by_45tokennwHLpSduQHMeta || {},
    component: () => import("/var/www/html/pages/reset-password/by-token.vue").then(m => m.default || m)
  },
  {
    name: "reset-password-change",
    path: "/reset-password/change",
    meta: changefnfBm7l90vMeta || {},
    component: () => import("/var/www/html/pages/reset-password/change.vue").then(m => m.default || m)
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: indexzcQPnc5ld1Meta || {},
    component: () => import("/var/www/html/pages/reset-password/index.vue").then(m => m.default || m)
  }
]