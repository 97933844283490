<template>
    <svg
        fill="none"
        height="20"
        viewBox="0 0 20 20"
        width="20"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="m8.33301 5.00065h-3.33334c-.44202 0-.86595.1756-1.17851.48816s-.48815.73648-.48815 1.17851v8.33338c0 .442.17559.8659.48815 1.1785.31256.3125.73649.4881 1.17851.4881h8.33333c.442 0 .866-.1756 1.1785-.4881.3126-.3126.4882-.7365.4882-1.1785v-3.3334m-3.3334-8.33332h5m0 0v5m0-5-8.33329 8.33332"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.66667"
        />
    </svg>
</template>
