const navigateToDashboard = (profile, type) => {
    if (type === 'member' || !unref(profile).frontend_management_access) {
        return navigateTo('/members/dashboard');
    }

    return redirectToAdminDashboard(profile);
};

const navigateToLogin = (type) => {
    const { public: config } = useRuntimeConfig();

    if (type === 'admin') {
        return navigateTo(config.loginPages.admin);
    }

    return navigateTo(config.loginPages.members);
};

export default defineNuxtRouteMiddleware(async (to) => {
    const access = to.meta?.access ?? { type: null, roles: [] };

    if (access.type === null) {
        return;
    }

    const { data: profile, status, refresh, refreshToken } = useAuth();

    await callOnce(async () => {
        if (status.value === 'unauthenticated' && !!refreshToken.value) {
            await refresh();
        }
    });

    if (access.type !== 'guest' && status.value === 'unauthenticated') {
        return navigateToLogin(access.type);
    }

    if (access.type === 'guest' && status.value === 'authenticated') {
        return navigateToDashboard(profile.value, access.type);
    }

    if (access.type === 'admin' && access.roles?.length) {
        const roles = profile.value.roles ?? [];

        // If the user does not have one of the required roles, redirect to the dashboard
        if (!roles.some((role) => access.roles.includes(role))) {
            return navigateToDashboard(profile.value, access.type);
        }
    }
});
