import type { RouterConfig } from '@nuxt/schema';
import type { RouteLocation, RouteRecordRaw, RouteRecordRedirect } from 'vue-router';

export default <RouterConfig>{
    routes: (_routes: Readonly<RouteRecordRaw[]>) => {
        return [
            ..._routes,
            {
                name: 'members.index',
                path: '/members',
                redirect: (_: RouteLocation) => {
                    // the function receives the target route as the argument
                    // we return a redirect path/location here.
                    return { path: '/members/dashboard' };
                },
            } as RouteRecordRedirect,
        ];
    },
};
